/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-card {
    max-width: 1400px;
    margin:10px auto;
}

ion-card-content {
    padding: 38px !important;
    background:#fff;
}

ion-card-content > h1 {
    padding:6px;
    color:rgb(39,39,39);
    font-weight:bold !important;
    font-size:38px !important;
}

p {
    padding-bottom:4px;
    color: rgb(153,51,0);
    font-size:18px !important;
}

ion-content {
    --ion-background-color:rgb(100, 179, 15);
}

.content-container {
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    // display: block;
    // align-items: center;
    // justify-content: center;
}

.footer {
    font-size:8pt;
    color:#fff;
    text-align:center;
}

ion-item > ion-label {
    font-size: 21px !important;
    color: rgb(153, 51, 0) !important;
}

.auto-resizable-iframe {
    max-width: 960px;
    margin: 0px auto;
}

.auto-resizable-iframe > div {
    position: relative;
    padding-bottom: 56%;
    height: 0px;
}

.auto-resizable-iframe iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.checkboxes {
    --background:#fff;
    --ion-background-color:#fff;
}

ion-menu {

ion-content {
    --background:#fff;
    --ion-background-color:#fff;
}

//   ion-list {/* optional, but needed when background is a gradient.*/
//     background: transparent;
//   }

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

}